<!-- App Main Structure -->
<template>
  <div id="wrapper">
    <!-- Header of Admin Panel -->
    <app-admin-header :key="componentKey"></app-admin-header>
    <div class="clearfix"></div>
    <div id="dashboard">
      <!-- Sidebar of Admin Panel -->
      <app-admin-sidebar></app-admin-sidebar>
      <!--Main Container -->
      <router-view @changeAvatar="forceRerender()" :key="$route.fullPath"></router-view>
      <app-admin-footer></app-admin-footer>
    </div>
  </div>
</template>

<script>
  import AdminHeader from 'Components/AdminHeader/AdminHeader.vue'
  import AdminSidebar from 'Components/AdminSidebar/AdminSidebar.vue'
  import Copyright from 'Components/Footer/Copyright.vue'

  export default {
    name: 'AdminPanel',
    data() {
      return {
        componentKey: 1,
      }
    },
    components: {
      appAdminHeader: AdminHeader,
      appAdminSidebar: AdminSidebar,
      appAdminFooter: Copyright,
    },
    computed: {
      roles() {
        if (this.$session.get('roles')) {
          let roles = []
          this.$session.get('roles').forEach((role) => {
            roles.push(role.name)
          })
          return roles
        } else {
          return null
        }
      },
    },
    created() {},
    methods: {
      forceRerender() {
        this.componentKey += 1
      },
      checkPermmissions() {
        if (this.roles) {
          if (this.roles.includes('admin', 'super_admin')) {
            this.$router.push('/admin/panel')
          } else {
            this.$router.push('/admin/perfil')
          }
        } else {
          this.$router.push('/')
        }
      },
    },
  }
</script>
