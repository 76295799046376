<!-- Header Structure -->
<template>
  <nav id="navigation" class="style-1">
    <ul id="responsive">
      <li
        v-for="menuItem in menuItems"
        v-bind:key="menuItem.name"
        :class="{ 'open-menu': selectedMenu == menuItem.name }"
      >
        <router-link
          class="current"
          data-toggle="collapse"
          data-target="#navbar_global"
          aria-controls="navbar_global"
          aria-expanded="false"
          :to="menuItem.state"
          v-if="menuItem.type === 'link'"
          >{{ menuItem.name }}</router-link
        >
        <a
          class="current"
          v-if="menuItem.type === 'sub'"
          @click="menuClick(menuItem.name)"
          >{{ menuItem.name }}</a
        >
        <ul v-if="menuItem.type === 'sub'">
          <li
            v-for="childitem in menuItem.children"
            :key="childitem.name"
            :class="{ 'open-menu': selectedSubMenu == childitem.name }"
          >
            <router-link
              :to="childitem.state"
              data-toggle="collapse"
              data-target="#navbar_global"
              aria-controls="navbar_global"
              aria-expanded="false"
              v-if="childitem.type === 'link'"
              >{{ childitem.name }}</router-link
            >
            <a v-if="childitem.type === 'sub'" @click="subMenuClick(childitem.name)">{{
              childitem.name
            }}</a>
            <ul v-if="childitem.type === 'sub'">
              <li
                v-for="subchilditem in childitem.children"
                v-bind:key="subchilditem.name"
              >
                <router-link :to="subchilditem.state">{{
                  subchilditem.name
                }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li :class="{ 'open-menu': selectedMenu == 'Mi cuenta' }">
        <a
          @click="menuClick('Mi cuenta')"
          class="current"
          href="#"
          id="navbar-primary_dropdown_1"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ><img
            v-if="avatar.route"
            :src="avatar.route"
            class="rounded-circle shadow-lg mr-1"
            width="45"
            height="45"
            alt=""
          />
          <img
            v-else
            :src="avatar"
            class="rounded-circle shadow-lg mr-1"
            width="45"
            height="45"
            alt=""
          />{{ name ? name : 'Mi cuenta' }}</a
        >
        <ul>
          <li v-if="!isLogged">
            <router-link style="padding-left: 10px" :to="'/session/login'"
              >Inicia sesión</router-link
            >
          </li>
          <li v-if="!isLogged">
            <router-link style="padding-left: 10px" :to="'/session/sign-up'"
              >Regístrate</router-link
            >
          </li>
          <li v-if="isLogged">
            <router-link style="padding-left: 10px" :to="'/admin/panel'"
              >Panel de Usuario</router-link
            >
          </li>
          <li v-if="isLogged">
            <a style="padding-left: 10px; cursor: pointer" @click.prevent="logout()"
              >Cerrar sesión</a
            >
          </li>
        </ul>
      </li>
      <li
        class="country-mobile"
        :class="{ 'open-menu': selectedMenu == 'Mi país' }"
        v-if="country"
      >
        <a
          @click="menuClick('Mi país')"
          class="current"
          href="#"
          id="navbar-primary_dropdown_2"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ><img
            :src="country.flag"
            class="shadow-lg mr-1"
            width="50"
            :alt="country.iso2"
          />{{ country.name }}</a
        >
        <ul
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="navbar-primary_dropdown_1"
          style="height: 500px; overflow-y: scroll"
        >
          <li>
            <a
              class="dropdown-item"
              data-toggle="collapse"
              data-target="#navbar_global"
              aria-controls="navbar_global"
              aria-expanded="false"
              @click.prevent="getCountry('AR')"
              ><img
                class="flag"
                :src="'https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg'"
                :alt="'flag'"
              />
              Argentina</a
            >
          </li>
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('BR')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg'"
              :alt="'flag'"
            />Brasil</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('BO')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Bolivia.svg'"
              :alt="'flag'"
            />Bolivia</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('CA')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg'"
              :alt="'flag'"
            />Canadá</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('CL')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg'"
              :alt="'flag'"
            />Chile</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('CO')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg'"
              :alt="'flag'"
            />Colombia</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('CR')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Costa_Rica_%28state%29.svg'"
              :alt="'flag'"
            />Costa Rica</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('EC')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/e/e8/Flag_of_Ecuador.svg'"
              :alt="'flag'"
            />Ecuador</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('SV')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_El_Salvador.svg'"
              :alt="'flag'"
            />El Salvador</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('ES')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg'"
              :alt="'flag'"
            />España</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('US')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg'"
              :alt="'flag'"
            />Estados Unidos</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('GT')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Guatemala.svg'"
              :alt="'flag'"
            />Guatemala</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('HN')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/c/ca/Naval_Ensign_of_Honduras.svg'"
              :alt="'flag'"
            />Honduras</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('MX')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg'"
              :alt="'flag'"
            />México</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('NI')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg'"
              :alt="'flag'"
            />Nicaragua</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('PA')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg'"
              :alt="'flag'"
            />Panamá</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('PY')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg'"
              :alt="'flag'"
            />Paraguay</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('PE')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg'"
              :alt="'flag'"
            />Perú</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('PT')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg'"
              :alt="'flag'"
            />Portugal</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('PR')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_Puerto_Rico.svg'"
              :alt="'flag'"
            />Puerto Rico</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('DO')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_the_Dominican_Republic.svg'"
              :alt="'flag'"
            />República Dominicana</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('UY')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg'"
              :alt="'flag'"
            />Uruguay</a
          >
          <a
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            @click.prevent="getCountry('VE')"
            ><img
              class="flag"
              :src="'https://upload.wikimedia.org/wikipedia/commons/7/7b/Flag_of_Venezuela_%28state%29.svg'"
              :alt="'flag'"
            />Venezuela</a
          >
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
  import { menus } from './menu-items.js'
  export default {
    data() {
      return {
        intervalFunction: '',
        menuItems: menus,
        selectedMenu: null,
        selectedSubMenu: null,
        country: JSON.parse(localStorage.getItem('selectedCountry')),
      }
    },
    watch: {
      name(newValue, oldValue) {},
      lastname(newValue, oldValue) {},
      avatar(newValue, oldValue) {},
      isLogged(newValue, oldValue) {},
    },
    computed: {
      isLogged() {
        if (
          localStorage.getItem('vue-session-key') &&
          JSON.parse(localStorage.getItem('vue-session-key')).tokenSession &&
          JSON.parse(localStorage.getItem('vue-session-key')).tokenSession != ''
        ) {
          return true
        } else {
          return false
        }
      },
      name() {
        if (this.$session.get('tokenSession')) {
          return this.$session.get('userName')
        } else {
          return false
        }
      },
      lastname() {
        if (this.$session.get('tokenSession')) {
          return this.$session.get('userLastname')
        } else {
          return false
        }
      },
      avatar() {
        if (this.$session.get('userAvatar')) {
          return this.$session.get('userAvatar')
        } else {
          return 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/user-icon-placeholder.png'
        }
      },
    },
    methods: {
      menuClick(value) {
        if (this.selectedMenu == value) {
          this.selectedMenu = null
        } else {
          this.selectedMenu = value
        }
      },

      subMenuClick(value) {
        if (this.selectedSubMenu == value) {
          this.selectedSubMenu = null
        } else {
          this.selectedSubMenu = value
        }
      },
      validateCountry() {
        if (!localStorage.getItem('selectedCountry')) {
          this.intervalFunction = setInterval(() => {
            this.setCountry()
          }, 100)
        }
      },
      setCountry() {
        this.country = JSON.parse(localStorage.getItem('selectedCountry'))
      },
      getCountry(countryCode) {
        localStorage.setItem('selectedCountryCode', countryCode)
        if (countryCode) {
          axios
            .get(process.env.VUE_APP_API_DIRECTORY + 'country-by-iso/' + countryCode)
            .then((response) => {
              this.country = response.data
              localStorage.setItem('selectedCountry', JSON.stringify(this.country))
              this.$emit('updateCountry', this.country)
            })
        }
      },
      logout() {
        this.$swal({
          title: `Hasta pronto ` + this.name + ' ' + this.lastname,
          html: `Regresa cuando quieras para seguir disfrutando de nuestros servicios`,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        })
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'auth/logout')
          .then(() => {
            this.$session.destroy()
          })
          .catch(() => {
            this.$session.destroy()
          })
          .finally(() => {
            setTimeout(() => {
              if (this.$route.path !== '/') {
                this.$router.push('/')
              } else {
                location.reload()
              }
            }, 4000)
          })
      },
    },
    beforeDestroy() {
      clearInterval(this.intervalFunction)
    },
  }
</script>
<style lang="scss" scoped>
  // Responsive Variables
  $xlmax-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
  $laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1499px)';
  $desktop-device: 'only screen and (min-width: 992px) and (max-width: 1199px)';
  $tablet-device: 'only screen and (min-width: 768px) and (max-width: 991px)';
  $large-mobile: 'only screen and (max-width: 767px)';
  $small-mobile: 'only screen and (max-width: 575px)';
  $extra-small-mobile: 'only screen and (max-width: 479px)';

  @media #{$desktop-device, $laptop-device, $xlmax-device} {
    #responsive {
      align-items: center;
      display: flex;
    }
  }

  .current {
    width: fit-content;
  }
  #navigation ul li:hover > #country-panel {
    transform: translate3d(-70%, 0, 0);
  }
  #responsive {
    align-items: center;
    pointer-events: all;
    li {
      cursor: pointer;
    }
  }

  .country-mobile {
    display: none;
  }

  @media (max-width: 991px) {
    .country-mobile {
      display: block;
    }

    .flag {
      width: 65px;
      height: 40px;
    }
    .dropdown-menu.show {
      background: content-box;
      height: 300px;
      padding-left: 20px;
    }
    .navbar-collapse.collapsing,
    .navbar-collapse.show {
      position: inherit;
    }
    #responsive {
      height: 70vh;
    }
    .current {
      width: inherit;
    }
    .dropdown-item:hover,
    .dropdown-item:focus {
      background-color: inherit;
    }
  }
</style>
