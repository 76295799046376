<!-- Header Structure -->
<template>
  <div class="app-admin-sidebar">
    <div class="db-admin">
      <div class="dashboard-nav">
        <div class="dashboard-nav-inner">
          <ul
            data-submenu-title="Panel Administrativo"
            v-if="roles.includes('admin', 'super_admin')"
          >
            <li class="active">
              <router-link to="/admin/panel"
                ><i class="fa fa-tachometer"></i> Panel principal</router-link
              >
            </li>
            <li v-if="roles.includes('super_admin')">
              <router-link to="/admin/usuarios"
                ><i class="fa fa-users"></i> Usuarios</router-link
              >
            </li>
            <li v-if="roles.includes('super_admin')">
              <router-link to="/admin/datos"><i class="fa fa-list"></i>Datos</router-link>
            </li>
            <li v-if="roles.includes('admin', 'super_admin')">
              <router-link to="/admin/franquicias"
                ><i class="fa fa-list"></i>Franquicias</router-link
              >
            </li>
            <li v-if="roles.includes('admin', 'super_admin')">
              <router-link to="/admin/asociaciones-franquicias"
                ><i class="fa fa-list"></i>Asociaciones de Franquicias</router-link
              >
            </li>
            <li v-if="roles.includes('admin', 'super_admin')">
              <router-link to="/admin/consultores"
                ><i class="fa fa-list"></i>Consultores</router-link
              >
            </li>
            <li v-if="roles.includes('admin', 'super_admin')">
              <router-link to="/admin/proveedores"
                ><i class="fa fa-list"></i>Proveedores</router-link
              >
            </li>
            <li v-if="roles.includes('admin', 'super_admin')">
              <router-link to="/admin/oferta-inmobiliaria"
                ><i class="fa fa-list"></i>Oferta Inmobiliaria</router-link
              >
            </li>
            <li v-if="roles.includes('super_admin')">
              <router-link to="/admin/negocios"
                ><i class="fa fa-list"></i>Negocios</router-link
              >
            </li>
            <li v-if="roles.includes('admin', 'super_admin')">
              <router-link to="/admin/publicidad"
                ><i class="fa fa-list"></i>Publicidad</router-link
              >
            </li>
            <li>
              <router-link to="/admin/publicaciones"
                ><i class="fa fa-list"></i>Publicaciones</router-link
              >
            </li>
            <li>
              <router-link to="/admin/categorias"
                ><i class="fa fa-list"></i>Categorias</router-link
              >
            </li>

            <li v-if="roles.includes('super_admin', 'admin')">
              <router-link to="/admin/solicitudes"
                ><i class="fa fa-list"></i>Formulario de solicitudes</router-link
              >
            </li>
            <li v-if="roles.includes('super_admin')">
              <router-link to="/admin/glosario"
                ><i class="fa fa-list"></i>Glosario</router-link
              >
            </li>
            <li v-if="roles.includes('super_admin')">
              <router-link to="/admin/estadisticas"
                ><i class="fa fa-list"></i>Estadisticas</router-link
              >
            </li>
          </ul>
          <ul data-submenu-title="Servicios de Usuario">
            <li class="active">
              <router-link to="/admin/panel"
                ><i class="fa fa-tachometer"></i> Panel principal</router-link
              >
            </li>
            <li v-if="roles.includes('advertiser')">
              <router-link to="/admin/cliente/publicidad"
                ><i class="fa fa-list"></i>Publicidad</router-link
              >
            </li>
            <li v-if="roles.includes('manager')">
              <router-link to="/admin/cliente/franquicias"
                ><i class="fa fa-list"></i>Franquicias</router-link
              >
            </li>
            <li v-if="roles.includes('manager')">
              <router-link to="/admin/cliente/proveedores"
                ><i class="fa fa-list"></i>Proveedores</router-link
              >
            </li>
            <li v-if="roles.includes('manager')">
              <router-link to="/admin/cliente/consultores"
                ><i class="fa fa-list"></i>Consultores</router-link
              >
            </li>
            <li v-if="roles.includes('manager')">
              <router-link to="/admin/cliente/asociaciones-franquicias"
                ><i class="fa fa-list"></i>Asociaciones de Franquicias</router-link
              >
            </li>
            <li v-if="roles.includes('manager')">
              <router-link to="/admin/cliente/oferta-inmobiliaria"
                ><i class="fa fa-list"></i>Oferta Inmobiliaria</router-link
              >
            </li>
          </ul>
          <ul data-submenu-title="Mi cuenta">
            <li>
              <router-link to="/admin/perfil"
                ><i class="fa fa-user"></i> Perfil</router-link
              >
            </li>
            <li>
              <router-link to="/admin/cliente/solicitudes"
                ><i class="fa fa-list"></i>Mis solicitudes</router-link
              >
            </li>
            <li>
              <a @click.prevent="logout()"
                ><i class="fa fa-sign-out"></i> Cerrar sesión</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <a class="db-trigger" @click="toggleMenu()">
      <i class="fa fa-reorder"></i>
    </a>
  </div>
</template>

<script>
  export default {
    computed: {
      roles() {
        if (this.$session.get('roles')) {
          return this.$session.get('roles').map((role) => {
            return role.name
          })
        } else {
          return []
        }
      },
    },
    methods: {
      toggleMenu() {
        if ($('#wrapper').hasClass('sidebar-in')) {
          $('#wrapper').removeClass('sidebar-in')
        } else {
          $('#wrapper').addClass('sidebar-in')
        }
      },
      logout() {
        this.$swal({
          title: `Hasta pronto ` + this.name + ' ' + this.lastname,
          html: `Regresa cuando quieras para seguir disfrutando de nuestros servicios`,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        })
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'auth/logout')
          .then(() => {
            this.$session.destroy()
          })
          .catch(() => {
            this.$session.destroy()
          })
          .finally(() => {
            setTimeout(() => {
              if (this.$route.path !== '/') {
                this.$router.push('/')
              } else {
                location.reload()
              }
            }, 4000)
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .dashboard-nav ul li a {
    color: #003a56;
    padding: 5px 10px;
  }
  /*.db-trigger {
		position: absolute;
		top: 0px;
		left: 100%;
	}*/
  .dashboard-nav-inner {
    padding-top: 0rem;
    height: 90vh;
    min-height: 100%;
    overflow: auto;
  }
</style>
